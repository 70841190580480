import React from 'react';
import styles from './style.module.scss';

type LoaderType = 'spinner' | 'dots' | 'bar' | 'custom';
interface WsButtonProps {
  onClick?: () => void;
  variant?:
    | 'primary'
    | 'secondary'
    | 'success'
    | 'danger'
    | 'warning'
    | 'info'
    | 'light'
    | 'dark';
  size?: 'sm' | 'md' | 'lg';
  disabled?: boolean;
  className?: string;
  type?: 'button' | 'submit' | 'reset'; // New type prop
  children?: React.ReactNode;
  style?: React.CSSProperties; // Add style prop
  id?: string;
  loaderType?: LoaderType;
  isLoading?: boolean; // New prop for loading state
  loadingIcon?: React.ReactNode; // New prop for custom loading icon
  customLoader?: React.ReactNode;
}

const WsButton: React.FC<WsButtonProps> = ({
  onClick,
  style = {},
  variant = 'primary',
  size = 'md',
  disabled = false,
  className = '',
  type = 'button', // Default type is 'button'
  id,
  children,
  isLoading = false, // Default to false
  loaderType = 'spinner',
  // loadingIcon = <span className={styles.loadingSpinner}>Loading...</span>,
  customLoader,
}) => {
  // const btnClasses = `${styles.btn} ${styles[`btn-${variant}`]} ${styles[`btn-${size}`]} ${className}`;

  const btnClasses = `${styles.btn} ${styles[`btn-${variant}`]} ${styles[`btn-${size}`]} ${className} ${isLoading ? styles.loading : ''}`;

  const renderLoader = () => {
    switch (loaderType) {
      case 'spinner':
        return <span className={styles.spinner}></span>;
      case 'dots':
        return <span className={styles.dots}></span>;
      case 'bar':
        return <span className={styles.bar}></span>;
      case 'custom':
        return customLoader || <span>Loading...</span>;
      default:
        return <span className={styles.spinner}></span>;
    }
  };

  return (
    <button
      className={btnClasses}
      onClick={onClick}
      disabled={disabled}
      id={id}
      type={type} // Pass the type prop to the button element
      style={style} // Apply custom inline styles
      aria-label={id}
    >
      {isLoading ? renderLoader() : ''}
      {children}
    </button>
  );
};

export default WsButton;
